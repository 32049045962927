<template>
  <div class="d-page-wrapper d-notifications-page">
    <div class="d-page-toolbar">
      <d-search-input
        v-model="search"
        @submit="onSearchSubmit"
      ></d-search-input>
    </div>

    <v-data-table
      :headers="tableHeaders"
      :items="tableItems"
      :page="pageNumber"
      :items-per-page="pageSize"
      :server-items-length="totalCount"
      disable-sort
      hide-default-footer
      class="d-data-table d-data-table--rows-clickable"
      @page-count="pageCount = $event"
      @click:row="openViewDialog"
    >
      <template v-slot:[`item.licenseNumber`]="{ item }">
        <v-icon class="d-data-table-cell-icon">mdi-flag</v-icon>
        {{ item.licenseNumber }}
      </template>
      <template v-slot:[`item.sentDateTimeUtc`]="{ item }">
        {{ dateTimeUtil.utcToLocalDisplayDateTimeFormat(item.sentDateTimeUtc) }}
      </template>
      <template v-slot:[`item.notificationTypeId`]="{ item }">
        {{ (getNotificationTypeById(item.notificationTypeId) || {}).name }}
      </template>
      <template v-slot:[`item.dealerId`]="{ item }">
        {{ (shortDealerById(item.dealerId) || {}).name }}
      </template>
    </v-data-table>

    <v-pagination
      v-model="pageNumber"
      :length="pageCount"
      :total-visible="15"
      class="my-5"
      @input="onPageInput"
    ></v-pagination>

    <notification-view-dialog
      :dialog.sync="viewDialog"
      :notification="viewDialog_notification"
    ></notification-view-dialog>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { notificationsPageSize } from '@/constants'

import dateTimeUtil from '@/utils/dateTime.util'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import notificationTypeMixin from '@/mixins/notificationType.mixin'

import DSearchInput from '@/components/DSearchInput'
import NotificationViewDialog from './NotificationViewDialog'


export default {
  components: {
    DSearchInput,
    NotificationViewDialog
  },


  mixins: [
    pageMixin,
    snackbarMixin,
    notificationTypeMixin
  ],


  data () {
    return {
      dateTimeUtil,
      search: null,
      pageCount: 0,
      pageNumber: 1,
      pageSize: notificationsPageSize,
      viewDialog: false,
      viewDialog_notification: null
    }
  },


  computed: {
    ...mapGetters('user', ['currentUserIsChainAdmin', 'currentUser']),
    ...mapGetters('chain', ['currentChainId']),
    ...mapGetters('dealer', ['currentDealerId', 'previousDealerId', 'shortDealerById']),
    ...mapGetters('notification', ['notificationsTableItems', 'notificationsTotalCount']),

    tableHeaders () {
      const tableHeaders = [
        { text: this.$t('notification.table.licenseNumber'), value: 'licenseNumber', cellClass: 'font-weight-medium text-no-wrap' },
        { text: this.$t('notification.table.type'), value: 'notificationTypeId' },
        { text: this.$t('notification.table.sentDateTime'), value: 'sentDateTimeUtc', cellClass: 'text-no-wrap' },
        { text: this.$t('notification.table.subject'), value: 'subject', cellClass: 'd-overflow-ellipsis d-notifications-table-cell-subject' },
        { text: this.$t('notification.table.message'), value: 'message', cellClass: 'd-overflow-ellipsis d-notifications-table-cell-message' }
      ]

      if (this.currentDealerId === '') {
        tableHeaders.push(
          { text: this.$t('notification.table.dealer'), value: 'dealerId', cellClass: 'd-overflow-ellipsis d-notifications-table-cell-message' }
        )
      }
      return tableHeaders
    },

    tableItems () {
      return this.notificationsTableItems(this.currentDealerId)
    },

    totalCount () {
      return this.notificationsTotalCount(this.currentDealerId)
    },

    chainId () {
      return this.currentUserIsChainAdmin ? this.currentChainId : this.currentUser.chainId
    }
  },


  watch: {
    async currentDealerId () {
      this.restoreInitialNotificationsTableItems(this.previousDealerId)
      this.search = null
      await this.reloadTableItems(1, true)
    },
    async currentChainId () {
      this.restoreInitialNotificationsTableItems(this.previousDealerId)
      this.search = null
      await this.reloadTableItems(1, true)
    }
  },


  methods: {
    ...mapActions('notification', ['loadNotificationsTableItems', 'restoreInitialNotificationsTableItems']),

    openViewDialog (notification) {
      this.viewDialog = true
      this.viewDialog_notification = notification
    },

    onSearchSubmit () {
      this.reloadTableItems(1)
    },

    onPageInput () {
      this.reloadTableItems()
    },

    async reloadTableItems (page, isInitial = false) {
      try {
        this.startPageLoading()

        if (page && page !== this.pageNumber) {
          this.pageNumber = page
        }

        const items = await this.loadNotificationsTableItems({
          chainId: this.chainId,
          dealerId: this.currentDealerId,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          search: this.search,
          isInitial
        })

        return items
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    }
  },


  mounted () {
    this.restoreInitialNotificationsTableItems(this.currentDealerId)
  },


  async created () {
    try {
      this.setPageTitle(this.$t('nav.notifications'))
      this.startPageLoading()

      await this.loadNotificationsTableItems({
        chainId: this.chainId,
        dealerId: this.currentDealerId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        isInitial: true
      })
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  }
}
</script>
