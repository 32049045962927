import { notificationType } from '@/constants'


const mixin = {
  computed: {
    notificationTypes () {
      const items = []

      for (const prop in notificationType) {
        const id = notificationType[prop]
        let name

        switch (id) {
          case notificationType.sms:
            name = this.$t('notificationType.sms')
            break
          case notificationType.email:
            name = this.$t('notificationType.email')
            break
        }

        items.push({ id, name })
      }

      return items
    }
  },


  methods: {
    getNotificationTypeById (id) {
      return this.notificationTypes.find(x => x.id === id)
    }
  }
}


export default mixin
