var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-page-wrapper d-notifications-page"},[_c('div',{staticClass:"d-page-toolbar"},[_c('d-search-input',{on:{"submit":_vm.onSearchSubmit},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"d-data-table d-data-table--rows-clickable",attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"page":_vm.pageNumber,"items-per-page":_vm.pageSize,"server-items-length":_vm.totalCount,"disable-sort":"","hide-default-footer":""},on:{"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.openViewDialog},scopedSlots:_vm._u([{key:"item.licenseNumber",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"d-data-table-cell-icon"},[_vm._v("mdi-flag")]),_vm._v(" "+_vm._s(item.licenseNumber)+" ")]}},{key:"item.sentDateTimeUtc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTimeUtil.utcToLocalDisplayDateTimeFormat(item.sentDateTimeUtc))+" ")]}},{key:"item.notificationTypeId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.getNotificationTypeById(item.notificationTypeId) || {}).name)+" ")]}},{key:"item.dealerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.shortDealerById(item.dealerId) || {}).name)+" ")]}}],null,true)}),_c('v-pagination',{staticClass:"my-5",attrs:{"length":_vm.pageCount,"total-visible":15},on:{"input":_vm.onPageInput},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}),_c('notification-view-dialog',{attrs:{"dialog":_vm.viewDialog,"notification":_vm.viewDialog_notification},on:{"update:dialog":function($event){_vm.viewDialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }