<template>
  <v-dialog
    v-if="notification"
    :value="dialog"
    :max-width="dialogWidth"
    content-class="d-dialog"
    scrollable
    @keydown.esc="closeDialog"
    @click:outside="closeDialog"
  >
    <v-card outlined class="d-dialog-card">
      <v-card-title>
        {{ dialogTitle }}
        <d-btn-icon
          type="close"
          btn-class="d-dialog-btn-close"
          @click="closeDialog"
        ></d-btn-icon>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <div
          v-if="notification.subject"
          v-text="notification.subject"
          class="text-body-1 text--primary mb-4"
        ></div>
        <div
          v-html="notification.message"
          class="text-body-2 text--primary"
        ></div>
        <div
          v-text="dateTimeUtil.utcToLocalDisplayDateTimeFormat(notification.sentDateTimeUtc)"
          class="text-caption mt-6 text-right"
        ></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import { notificationType } from '@/constants'

import dateTimeUtil from '@/utils/dateTime.util'

import dialogMixin from '@/mixins/dialog.mixin'
import notificationTypeMixin from '@/mixins/notificationType.mixin'

import DBtnIcon from '@/components/DBtnIcon'


export default {
  props: {
    notification: Object
  },


  components: {
    DBtnIcon
  },


  mixins: [
    dialogMixin,
    notificationTypeMixin
  ],


  data () {
    return {
      dateTimeUtil
    }
  },


  computed: {
    dialogTitle () {
      let title = (this.getNotificationTypeById(this.notification.notificationTypeId) || {}).name

      if (this.notification.licenseNumber) {
        title += ` ${this.$t('notification.to')} ${this.notification.licenseNumber}`
      }

      return title
    },

    dialogWidth () {
      let width = 500

      switch (this.notification.notificationTypeId) {
        case notificationType.sms:
          width = 450
          break
        case notificationType.email:
          width = 600
          break
      }

      return width
    }
  }
}
</script>
