import { dateTimeFormats } from '@/constants'
import moment from 'moment'

const utcToLocalFormat = (date, format) => {
  return moment.utc(date).local().format(format)
}

export default {
  utcToLocalFormat,

  utcToLocalDisplayDateFormat (date) {
    return utcToLocalFormat(date, dateTimeFormats.displayDate)
  },

  utcToLocalDisplayDateTimeFormat (dateTime) {
    return utcToLocalFormat(dateTime, dateTimeFormats.displayDateTime)
  }
}
